<script lang="ts" setup>
import {computed, ref} from "vue"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import AMainSection from "@/components/AMainSection.vue"
import CoachProfileForm from "@/components/CoachProfileForm.vue"
import InlineForm from "@/components/InlineForm.vue"
import {useConfirm} from "vuetify-use-dialog"
import AppTable from "@/components/AppTable.vue"
import useRouteTo from "@/composables/useRouteTo"
import AddReviewDialog from "@/components/AddReviewDialog.vue"

const {routeTo, ROUTES} = useRouteTo()
const { axios } = useAxios()
const confirm = useConfirm()

const props = defineProps<{
  coachId: number;
}>()

const coach = ref<(App.Models.User & {password_confirmation: null | string}) | null>()

const headers = [
  {
    title: 'Status',
    key: 'status',
    sortable: false
  },
  {
    title: 'Coach',
    key: 'coach.full_name',
    sortable: false
  },
  {
    title: 'Service',
    key: 'service',
    sortable: false
  },
  {
    title: '',
    key: 'actions',
    sortable: false
  },
]

const load = async () => {
  const {data} = await axios.get(`/admin/coaches/${props.coachId}`)
  coach.value = {...data, password_confirmation: null}
}

const status = computed(() => {
  if (coach.value?.deactivated_at) {
    return 'Disabled'
  }
  if (coach.value?.is_active) {
    return 'Active'
  }
  if (coach.value?.approval_status === 'pending') {
    return 'Pending'
  }
  if (!coach.value?.is_profile_completed) {
    return 'Completing Profile'
  }

  return 'Rejected'
})

const tab = ref<string>('profile')

const confirmDisable = async (callback: () => Promise<void>) => {
  if (coach.value?.deactivated_at) {
    await callback()
    return
  }

  const response = await confirm({
    title: 'Are you sure?',
    content: `Are you sure you want to disable ${coach.value?.full_name} account?`,
  })

  if (!response) {
    return
  }
  await callback()
}

const showReview = ref(false)

</script>

<template>
  <a-loader :action="load">
    <a-main-section
      :heading="coach?.full_name"
      return-text="Manage Coaches"
      :return-to="routeTo(ROUTES.ADMIN.COACHES.INDEX)"
    >
      <v-card
        class="mb-12"
        color="primary"
        variant="outlined"
      >
        <v-card-title class="d-flex justify-space-between">
          Admin Options
          <div>
            <span>Status:&nbsp;</span>
            <span class="text-capitalize text-black">{{ status }}</span>
          </div>
        </v-card-title>
        <v-card-text class="d-flex justify-space-between ga-4">
          <inline-form
            v-if="coach?.approval_status === 'pending'"
            v-slot="{ submit, loading }"
            :endpoint="`/admin/coaches/${coach.id}/approve`"
            @success="load"
          >
            <v-btn
              :loading="loading"
              color="primary"
              text="Approve Profile &amp; Send Invite"
              @click="submit"
            />
          </inline-form>
          <inline-form
            v-if="coach?.is_active"
            v-slot="{ submit, loading }"
            method="post"
            :endpoint="`/admin/coaches/${coach.id}/toggle-featured`"
            @success="load"
          >
            <v-btn
              :loading="loading"
              color="primary"
              :text="coach.is_featured ? 'Un-Feature Coach' : 'Feature Coach'"
              @click="submit"
            />
          </inline-form>
          <inline-form
            v-if="coach?.is_active"
            v-slot="{ loading }"
            method="post"
            :endpoint="`/admin/coaches/${coach}/toggle-featured`"
            @success="load"
          >
            <v-btn
              :loading="loading"
              color="primary"
              text="Add Review"
              @click="showReview = true"
            />
          </inline-form>
          <v-spacer />
          <inline-form
            v-if="coach?.approval_status === 'approved'"
            v-slot="{ submit, loading }"
            :endpoint="`/admin/coaches/${coach.id}/toggle-enabled`"
            method="post"
            @success="load"
          >
            <v-btn
              :loading="loading"
              color="primary"
              variant="outlined"
              :text="coach.deactivated_at ? 'Enable Coach' : 'Disable Coach'"
              @click="confirmDisable(submit)"
            />
          </inline-form>
        </v-card-text>
      </v-card>
      <v-tabs v-model="tab">
        <v-tab
          text="Profile"
          value="profile"
        />
        <v-tab
          text="Appointments"
          value="appointments"
        />
      </v-tabs>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="profile">
          <coach-profile-form
            v-if="coach"
            class="mt-6"
            :endpoint="`/admin/coaches/${coachId}`"
            method="put"
            v-model="coach"
          />
        </v-tabs-window-item>
        <v-tabs-window-item value="appointments">
          <app-table
            :endpoint="`/admin/coaches/${coach?.id}/appointments`"
            striped
            :headers="headers"
          >
            <template #item.service="{value}">
              {{ value.name }} {{ value.delivery_method }} {{ value.format }}
            </template>
            <template #item.actions="{item}">
              <router-link
                :to="routeTo(ROUTES.ADMIN.COACHES.APPOINTMENT, { appointmentId: item.id, coachId: props.coachId })"
              >
                <v-icon color="primary">
                  mdi-open-in-new
                </v-icon>
              </router-link>
            </template>
          </app-table>
        </v-tabs-window-item>
      </v-tabs-window>
      <add-review-dialog
        v-model="showReview"
        :coach-id="coach?.id"
        @success="load"
      />
    </a-main-section>
  </a-loader>
</template>
