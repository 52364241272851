<script setup lang="ts">
import ALoader from "@/components/ALoader.vue"
import CoachServiceCard from "@/components/CoachServiceCard.vue"
import useAxios from "@/composables/useAxios"
import {computed, ref} from "vue"
import AppServerValidationForm from "@/components/AppServerValidationForm.vue"
import useFile from "@/composables/useFile"
import useAuth from "@/composables/useAuth"
import useRouteTo from "@/composables/useRouteTo"
import ReviewCard from "@/components/ReviewCard.vue"
import {useAppStore} from "@/stores/app"

const {axios} = useAxios()
const fileUrl = useFile()
const {$auth} = useAuth()
const {routeTo, ROUTES} = useRouteTo()

const props = defineProps<{
  appointmentId: string | number,
}>()

const appointment = ref<App.Models.Appointment | null>()
const originalStatus = ref<string>('')
const hasNote = ref<boolean>(false)
const isFinished = computed(() => {
  return appointment.value?.available_statuses.length === 0
})
const appStore = useAppStore()

const urlPrefix = computed(() => {
  if ($auth.isCoach) {
    return '/coach/appointments'
  } else if ($auth.isUser) {
    return '/appointments'
  } else {
    return '/admin/appointments'
  }
})

const load = async () => {
  const {data} = await axios.get(`${urlPrefix.value}/${props.appointmentId}`, {})
  appointment.value = data
  originalStatus.value = data.status
  hasNote.value = !!data.coach_note
}

const requestReview = async () => {
  await axios.post(`${urlPrefix.value}/${props.appointmentId}/request-review`, {})
  appStore.addSuccess('Review requested')
}

</script>

<template>
  <a-loader :action="load">
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column gr-6"
        v-if="!$auth.isUser"
      >
        <h1 class="heading">
          Details
        </h1>
        <div>
          <h5 class="sub-heading">
            Requested by
          </h5>
          <p>{{ appointment!.user!.full_name }}</p>
          <p>{{ appointment!.user!.email }}</p>
          <p>{{ appointment!.user!.phone }}</p>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column gr-6"
      >
        <h1 class="heading">
          Service
        </h1>
        <coach-service-card
          :service="appointment!.service!"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <h1 class="heading mb-2">
          Manage Appointment
        </h1>
        <app-server-validation-form
          v-if="!hasNote && !$auth.isUser"
          :data="appointment"
          full-width
          :endpoint="`${urlPrefix}/${ appointment!.id }/note`"
          @success="load"
          v-slot="{ submit, loading, getErrors }"
        >
          <v-label class="label mb-2">
            Message to {{ appointment!.user!.full_name }}
          </v-label>
          <v-textarea
            v-model="appointment!.coach_note"
            outlined
            :error-messages="getErrors('coach_note')"
            rows="5"
          />
          <v-btn
            color="primary"
            size="x-large"
            text="Submit"
            :loading="loading"
            @click="submit"
          />
        </app-server-validation-form>
        <app-server-validation-form
          v-else-if="appointment!.coach_note"
          :data="appointment"
          full-width
          :endpoint="`${urlPrefix}/${ appointment!.id }/unpublish-note`"
          @success="load"
          v-slot="{ submit, loading }"
        >
          <v-card>
            <v-card-text>
              <p class="text-subtitle-1">
                {{ appointment!.coach_note }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-list-item>
                <template #prepend>
                  <v-avatar
                    key="prepend-avatar"
                    :image="fileUrl(appointment!.coach!.file_id)"
                  />
                </template>
                <v-list-item-title>{{ appointment!.coach!.full_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ appointment!.coach!.title }}</v-list-item-subtitle>
              </v-list-item>
              <v-spacer />
              <v-btn
                v-if="!$auth.isUser"
                color="primary"
                size="x-large"
                text="Unpublish"
                variant="flat"
                :loading="loading"
                @click="submit"
              />
            </v-card-actions>
          </v-card>
        </app-server-validation-form>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-label class="label mb-6">
          Status
        </v-label>
        <app-server-validation-form
          :data="appointment"
          full-width
          @success="load"
          :endpoint="`${urlPrefix}/${appointment!.id}`"
          method="put"
          v-if="!$auth.isUser"
          v-slot="{ submit, loading, getErrors }"
        >
          <v-select
            v-model="appointment!.status"
            :items="[...appointment!.available_statuses, originalStatus]"
            hide-selected
            :disabled="isFinished"
            outlined
            :error-messages="getErrors('status')"
          />
          <v-btn
            color="primary"
            size="x-large"
            text="Save"
            :disabled="isFinished"
            :loading="loading"
            @click="submit"
          />
        </app-server-validation-form>
        <p v-else>
          {{ appointment!.status }}
        </p>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex flex-column gr-6"
      >
        <v-label class="label">
          User Review
        </v-label>
        <p v-if="originalStatus !== 'Completed'">
          Reviews cannot be added until the appointment is marked complete.
        </p>
        <v-btn
          color="primary"
          class="align-self-start"
          :to="routeTo(ROUTES.USERS.REVIEW, {appointmentId: appointment!.id})"
          v-else-if="!$auth.isCoach && originalStatus === 'Completed' && !appointment!.review"
        >
          Add Review
        </v-btn>
        <v-btn
          color="primary"
          class="align-self-start"
          @click="requestReview"
          v-else-if="!$auth.isUser && originalStatus === 'Completed' && !appointment!.review"
        >
          Request Review
        </v-btn>
        <review-card
          :review="appointment!.review!"
          v-else
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex flex-column gr-6"
        v-if="appointment!.session_id"
      >
        <v-label class="label">
          Receipt
        </v-label>
        <h4 v-if="!$auth.isUser">
          Payment Status: {{ appointment!.payment_complete ? 'Paid' : 'Unpaid' }}
        </h4>
        <a
          v-if="appointment!.payment_complete"
          class="text-primary"
          target="_blank"
          :href="appointment!.receipt_url"
        >
          Receipt
        </a>
        <v-btn
          v-else-if="$auth.isUser"
          color="primary"
          :to="routeTo(ROUTES.USERS.APPOINTMENT_CHECKOUT, {appointmentId: appointment!.id})"
        >
          Complete Purchase
        </v-btn>
      </v-col>
    </v-row>
  </a-loader>
</template>

<style scoped lang="scss">
.heading {
  color: rgb(var(--v-theme-primary));
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 26px;
  font-weight: 600;
}

.sub-heading {
  color: #000;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 22px;
  font-weight: 500;
}

.label {
  color: #000;
  opacity: 1;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
}
</style>
