<script setup lang="ts">
import {computed, ref} from "vue"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import useRouteTo from "@/composables/useRouteTo"
import CoachHero from "@/components/CoachHero.vue"
import CoachServiceCard from "@/components/CoachServiceCard.vue"
import {useRouter} from "vue-router"
import {useHead} from "@unhead/vue"
import CalendlyEmbed from "@/components/CalendlyEmbed.vue"
import errorHandler from "@/plugins/errorHandler"

useHead({ title: 'Booking' })

const {routeTo, ROUTES} = useRouteTo()
const router = useRouter()

const props = defineProps<{
  coachId: string | number;
  serviceId?: string | number;
}>()

const scheduled = async ({event: {uri: event_uri}, invitee: {uri: invitee_uri}}) => {
  const {data: {id, message, errors}, status} = await axios.post('/appointments', {
    service_id: props.serviceId,
    coach_id: props.coachId,
    event_uri,
    invitee_uri,
  }).catch(e => e)
  if (errorHandler(status, message, errors)) return
  await router.push(routeTo(ROUTES.USERS.APPOINTMENT_CHECKOUT, {appointmentId: id}))
}



const {axios} = useAxios()
const coach = ref<(App.Models.User) | null>()
const load = async () => {
  const {data} = await axios.get(`/coaches/${props.coachId}`)
  coach.value = data
}

const selectedService = computed(() => {
  if (coach.value?.consultation_service?.id === props.serviceId) {
    return coach.value?.consultation_service
  }
  return coach.value?.services.find(service => service.id === props.serviceId)
})

</script>

<template>
  <a-loader :action="load">
    <coach-hero
      :coach="coach!"
      return-text="Review Coach"
      :return-to="routeTo(ROUTES.COACH_PROFILE, {coachId: props.coachId})"
      hide-bio
    />
    <v-container>
      <v-row>
        <v-col
          order="2"
          order-md="1"
          cols="12"
          md="6"
          class="d-flex flex-column gr-6"
        >
          <h1 class="heading">
            Booking
          </h1>
          <calendly-embed
            @event-scheduled="scheduled"
            :coach="coach!"
          />
        </v-col>
        <v-col
          order="1"
          order-md="2"
          cols="12"
          md="6"
          class="d-flex flex-column gr-6"
        >
          <h1 class="heading">
            Service
          </h1>
          <coach-service-card
            v-if="selectedService"
            :service="selectedService"
          />
        </v-col>
      </v-row>
    </v-container>
  </a-loader>
</template>

<style scoped lang="scss">
.heading {
  color: rgb(var(--v-theme-primary));
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 26px;
  font-weight: 600;
}

.label {
  color: #000;
  opacity: 1;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  white-space: break-spaces;
}

.sub-label {
  color: #000;
  opacity: 1;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Lato, sans-serif;
  font-size: 20px;
  letter-spacing: 0.6px;
  white-space: pre-line;
}
</style>
