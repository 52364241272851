import RouteDesigner from "@/router/RouteDesigner"
import Authentication from "@/middleware/Authentication"
import Authorization from "@/middleware/Authorization"
import CoachCompleteProfile from "@/middleware/CoachCompleteProfile"

import ForceTypes from "@/middleware/ForceTypes"
import HomePage from "@/pages/HomePage.vue"
import LoginPage from "@/pages/LoginPage.vue"
import UsersRegistrationPage from "@/pages/Users/RegistrationPage.vue"
import CoachesCreatePage from "@/pages/Coaches/CreatePage.vue"
import AdminAdminsIndexPage from '@/pages/Admin/Admins/IndexPage.vue'
import AdminAdminsCreatePage from '@/pages/Admin/Admins/CreatePage.vue'
import AdminAdminsEditPage from '@/pages/Admin/Admins/EditPage.vue'
import AdminFocusAreaIndexPage from '@/pages/Admin/FocusAreas/IndexPage.vue'
import AdminFocusAreaCreatePage from '@/pages/Admin/FocusAreas/CreatePage.vue'
import AdminFocusAreaEditPage from '@/pages/Admin/FocusAreas/EditPage.vue'
import AdminUsersIndexPage from '@/pages/Admin/Users/IndexPage.vue'
import AdminUsersEditPage from '@/pages/Admin/Users/EditPage.vue'
import AdminCoachesIndexPage from '@/pages/Admin/Coaches/IndexPage.vue'
import AdminCoachesCreatePage from '@/pages/Admin/Coaches/CreatePage.vue'
import AdminCoachesEditPage from '@/pages/Admin/Coaches/EditPage.vue'
import AdminCoachInvitesCreatePage from '@/pages/Admin/CoachInvites/CreatePage.vue'
import AdminAppointmentsIndexPage from '@/pages/Admin/Appointments/IndexPage.vue'
import UsersProfilePage from '@/pages/Users/ProfilePage.vue'
import ForgotPasswordPage from "@/pages/ForgotPasswordPage.vue"
import SetPasswordPage from "@/pages/SetPasswordPage.vue"
import CoachEmailLoginPage from "@/pages/CoachEmailLoginPage.vue"
import CoachesProfilePage from '@/pages/Coaches/ProfilePage.vue'
import CoachesProfileProfilePage from '@/pages/Coaches/ProfileProfilePage.vue'
import CoachesProfileAppointmentPage from '@/pages/Coaches/ProfileAppointmentPage.vue'
import CoachesCompleteProfilePage from '@/pages/Coaches/CompleteProfilePage.vue'
import CoachesCreateSentPage from "@/pages/Coaches/CreateSentPage.vue"
import TermsAndConditionsPage from "@/pages/TermsAndConditionsPage.vue"
import PrivacyPolicyPage from "@/pages/PrivacyPolicyPage.vue"
import DisclaimerPage from "@/pages/DisclaimerPage.vue"

import CoachProfilePage from "@/pages/CoachProfilePage.vue"
import BookingPage from "@/pages/BookingPage.vue"
import UserAppointmentPage from "@/pages/Users/AppointmentPage.vue"
import CoachAppointmentPage from "@/pages/Coaches/AppointmentPage.vue"
import AdminAppointmentPage from "@/pages/Admin/Coaches/AppointmentPage.vue"
import ReviewPage from "@/pages/ReviewPage.vue"
import FocusAreaPage from "@/pages/FocusAreaPage.vue"
import SearchPage from "@/pages/SearchPage.vue"
import MasterServicesAgreementPage from "@/pages/MasterServicesAgreementPage.vue"
import CheckoutPage from "@/pages/CheckoutPage.vue"

RouteDesigner.setNotFound("Error404", { layout: "Empty" })

export const ROUTES = {
  LOGIN: "login",
  SEARCH: 'search',
  FORGOT_PASSWORD: 'forgot-password',
  SET_PASSWORD: "set-password",
  COACH_EMAIL_LOGIN: 'coach-email-login',
  HOME: "home",
  TERMS_AND_CONDITIONS: "terms-of-service",
  PRIVACY_POLICY: "privacy-policy",
  MASTER_SERVICES_AGREEMENT: "master-services-agreement",
  DISCLAIMER: "disclaimer",
  COACH_PROFILE: "coach-profile",
  FOCUS_AREA: "focus-area",
  ADMIN: {
    PROFILE: 'admin.profile',
    ADMINS: {
      INDEX: 'admin.admins.index',
      CREATE: 'admin.admins.create',
      EDIT: 'admin.admins.edit',
    },
    USERS: {
      INDEX: 'admin.users.index',
      EDIT: 'admin.users.edit',
    },
    COACHES: {
      INDEX: 'admin.coaches.index',
      CREATE: 'admin.coaches.create',
      EDIT: 'admin.coaches.edit',
      INVITE: 'admin.coaches.invite',
      APPOINTMENT: 'admin.coaches.appointments',
    },
    FOCUS_AREAS: {
      INDEX: 'admin.focus_areas.index',
      CREATE: 'admin.focus_areas.create',
      EDIT: 'admin.focus_areas.edit',
    },
    APPOINTMENTS: {
      INDEX: 'admin.appointments.index',
    }
  },
  USERS: {
    CREATE: 'users.create',
    PROFILE: 'users.edit',
    APPOINTMENT: "appointment",
    APPOINTMENT_REQUEST: "appointment-request",
    APPOINTMENT_CHECKOUT: "appointment-checkout",
    REVIEW: "review",
  },
  COACHES: {
    CREATE: 'coaches.create',
    COMPLETE_PROFILE: 'coaches.complete-profile',
    PROFILE: {
      PROFILE: 'coaches.profile',
      APPOINTMENTS: 'coaches.appointments',
    },
    APPOINTMENT: 'coaches.appointment',
    CHECKOUT: 'coaches.checkout',
    CREATE_REQUEST_SENT: 'coaches.awaiting-confirmation',
  },
} as const

RouteDesigner.group({ middleware: [ForceTypes, Authentication] }, function() {
  RouteDesigner.group({ layout: 'Default' }, function () {
    RouteDesigner.route("/", HomePage, ROUTES.HOME)
    RouteDesigner.route('/search', SearchPage, ROUTES.SEARCH)
    RouteDesigner.route("/login", LoginPage, ROUTES.LOGIN)
    RouteDesigner.route('/register', UsersRegistrationPage, ROUTES.USERS.CREATE)
    RouteDesigner.route('/register/coach', CoachesCreatePage, ROUTES.COACHES.CREATE)
    RouteDesigner.route('/register/coach/sent', CoachesCreateSentPage, ROUTES.COACHES.CREATE_REQUEST_SENT)
    RouteDesigner.route('/register/coach/email-login', CoachEmailLoginPage, ROUTES.COACH_EMAIL_LOGIN)
    RouteDesigner.route('/forgot-password', ForgotPasswordPage, ROUTES.FORGOT_PASSWORD)
    RouteDesigner.route('/set-password/:token', SetPasswordPage, ROUTES.SET_PASSWORD).passProps()
    RouteDesigner.route("/terms-of-service", TermsAndConditionsPage, ROUTES.TERMS_AND_CONDITIONS)
    RouteDesigner.route("/disclaimer", DisclaimerPage, ROUTES.DISCLAIMER)
    RouteDesigner.route("/privacy-policy", PrivacyPolicyPage, ROUTES.PRIVACY_POLICY)
    RouteDesigner.route("/master-services-agreement", MasterServicesAgreementPage, ROUTES.MASTER_SERVICES_AGREEMENT)
    RouteDesigner.route("/coach/:coachId", CoachProfilePage, ROUTES.COACH_PROFILE).passProps()
    RouteDesigner.route("/focus-area/:focusAreaId", FocusAreaPage, ROUTES.FOCUS_AREA).passProps()

    RouteDesigner.group({ middleware: [Authorization] }, function() {
      // User Routes
      RouteDesigner.group({ type: 'user' }, function() {
        RouteDesigner.route('/profile', UsersProfilePage, ROUTES.USERS.PROFILE)
        RouteDesigner.route('/appointments/:appointmentId/review', ReviewPage, ROUTES.USERS.REVIEW).passProps()
        RouteDesigner.route('/appointments/request/:coachId/:serviceId?', BookingPage, ROUTES.USERS.APPOINTMENT_REQUEST).passProps()
        RouteDesigner.route('/appointments/:appointmentId/checkout', CheckoutPage, ROUTES.USERS.APPOINTMENT_CHECKOUT).passProps()
        RouteDesigner.route('/appointments/:appointmentId', UserAppointmentPage, ROUTES.USERS.APPOINTMENT).passProps()
      })

      // Coach Routes
      RouteDesigner.group({ prefix: 'coach', type: 'coach' }, function () {
        RouteDesigner.route('/complete-profile', CoachesCompleteProfilePage, ROUTES.COACHES.COMPLETE_PROFILE)
        RouteDesigner.group({ middleware: [CoachCompleteProfile] }, function () {
          RouteDesigner.route('/appointments/:appointmentId', CoachAppointmentPage, ROUTES.COACHES.APPOINTMENT).passProps()
          RouteDesigner.route('/profile', CoachesProfilePage).children(function () {
            RouteDesigner.route('', CoachesProfileProfilePage, ROUTES.COACHES.PROFILE.PROFILE)
            RouteDesigner.route('/appointments', CoachesProfileAppointmentPage, ROUTES.COACHES.PROFILE.APPOINTMENTS)
          })
        })
      })

      // Admin Routes
      RouteDesigner.group({ prefix: 'admin', type: 'admin' }, function () {
        RouteDesigner.route('/profile', UsersProfilePage, ROUTES.ADMIN.PROFILE)
        RouteDesigner.group({ prefix: 'admins' }, function () {
          RouteDesigner.route('/', AdminAdminsIndexPage, ROUTES.ADMIN.ADMINS.INDEX)
          RouteDesigner.route('/create', AdminAdminsCreatePage, ROUTES.ADMIN.ADMINS.CREATE)
          RouteDesigner.route('/edit/:admin', AdminAdminsEditPage, ROUTES.ADMIN.ADMINS.EDIT).passProps()
        })

        RouteDesigner.group({ prefix: 'users' }, function () {
          RouteDesigner.route('/', AdminUsersIndexPage, ROUTES.ADMIN.USERS.INDEX)
          RouteDesigner.route('/edit/:user', AdminUsersEditPage, ROUTES.ADMIN.USERS.EDIT).passProps()
        })

        RouteDesigner.group({ prefix: 'coaches' }, function () {
          RouteDesigner.route('/', AdminCoachesIndexPage, ROUTES.ADMIN.COACHES.INDEX)
          RouteDesigner.route('/create', AdminCoachesCreatePage, ROUTES.ADMIN.COACHES.CREATE)
          RouteDesigner.route('/edit/:coachId', AdminCoachesEditPage, ROUTES.ADMIN.COACHES.EDIT).passProps()
          RouteDesigner.route('/edit/:coachId/appointment/:appointmentId', AdminAppointmentPage, ROUTES.ADMIN.COACHES.APPOINTMENT).passProps()
          RouteDesigner.route('/invite', AdminCoachInvitesCreatePage, ROUTES.ADMIN.COACHES.INVITE)
        })

        RouteDesigner.group({ prefix: 'focus-areas' }, function () {
          RouteDesigner.route('/', AdminFocusAreaIndexPage, ROUTES.ADMIN.FOCUS_AREAS.INDEX)
          RouteDesigner.route('/create', AdminFocusAreaCreatePage, ROUTES.ADMIN.FOCUS_AREAS.CREATE)
          RouteDesigner.route('/edit/:focusAreaId', AdminFocusAreaEditPage, ROUTES.ADMIN.FOCUS_AREAS.EDIT).passProps()
        })
        RouteDesigner.group({ prefix: 'appointments' }, function () {
          RouteDesigner.route('/', AdminAppointmentsIndexPage, ROUTES.ADMIN.APPOINTMENTS.INDEX)
        })
      })
    })
  })
})
