import { createWebHistory, createRouter } from "vue-router"
import "@/router/paths"
import RouteDesigner from "@/router/RouteDesigner"
import Pipeline from "@/middleware/Pipeline"
import {ROUTES} from "@/router/paths"
import { usePostHog } from '@/composables/usePostHog'
import {nextTick} from "vue"


const router = createRouter({
  history: createWebHistory(),
  routes: RouteDesigner.compile(),
  scrollBehavior(to, from, savedPosition) {
    if (
      from &&
      to.name == ROUTES.SEARCH &&
      from.name == ROUTES.SEARCH &&
      Object.keys(to.query).length &&
      to.fullPath.split('?')[0] == from.fullPath.split('?')[0]
    )  {
      return
    }

    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash }
    }
    return { top: 0 }
  }
})




const pipeline = new Pipeline()
router.beforeEach(pipeline.handle.bind(pipeline))

if (import.meta.env.PROD)  {
// Post Hog Analytics
  const { posthog } = usePostHog()
  router.afterEach((to, _from, failure) => {
    if (failure) return
    nextTick(() => {
      posthog.capture('$pageview', {path: to.fullPath})
    }).then()
  })
  router.beforeEach((to, from) => {
    if (from.path !== to.path) {
      posthog.capture('$pageleave', {path: from.fullPath})
    }
  })
}

export default router
