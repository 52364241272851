<script setup lang="ts">
import AMainSection from "@/components/AMainSection.vue"
import useAuth from "@/composables/useAuth"
import useRouteTo from "@/composables/useRouteTo"

const {$auth} = useAuth()
const {routeTo, ROUTES} = useRouteTo()

</script>

<template>
  <a-main-section
    sub-heading="My Account"
    :heading="$auth.user!.full_name"
  >
    <v-tabs
      style="margin-bottom: 32px"
      show-arrows
      stacked
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
    >
      <v-tab :to="routeTo(ROUTES.COACHES.PROFILE.PROFILE)">
        Profile
      </v-tab>
      <v-tab :to="routeTo(ROUTES.COACHES.PROFILE.APPOINTMENTS)">
        Appointments
      </v-tab>
    </v-tabs>
    <router-view />
  </a-main-section>
</template>
