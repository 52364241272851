<template>
  <v-skeleton-loader
    v-if="loading"
    type="card"
    height="500"
  />
  <div
    ref="calendar"
  />
</template>

<script setup lang="ts">
import {useHead} from "@unhead/vue"
import {onMounted, onUnmounted, ref, watch} from "vue"
import {$auth} from "@/plugins/auth"
import {kebabCase} from "lodash"

const loading = ref<boolean>(true)
const calendar = ref<HTMLDivElement>()
const emits = defineEmits<{
  'profile-page-viewed': [payload: {}]
  'event-type-viewed': [payload: {}]
  'date-and-time-selected': [payload: {}],
  'event-scheduled': [payload: {event: {uri: string}, invitee: {uri: string}}],
  'page-height': [payload: {height: string}],
}>()

useHead({
  script: [
    {
      src: 'https://assets.calendly.com/assets/external/widget.js',
      async: true,
      id: 'calendly-script',
      onload: () => {
        loadCalendly()
      },
    },
  ],
})


const props = defineProps<{
  coach: App.Models.User,
}>()

const isCalendlyEvent = (event: MessageEvent) => {
  return event.data.event && event.data.event.indexOf('calendly') === 0
}

const handleMessage = (event: MessageEvent) => {
  if (!isCalendlyEvent(event)) return
  emits(kebabCase(event.data.event.split('.')[1] as string), event.data.payload)
}

const loadCalendly = () => {
  if (!loading.value || !window.Calendly || !calendar.value) return
  window.Calendly.initInlineWidget({
    url: props.coach.calendly_link + '?hide_landing_page_details=1&hide_gdpr_banner=1&hide_event_type_details=1',
    parentElement: calendar.value,
    prefill: {
      name: $auth.user?.full_name,
      email: $auth.user?.email,
    },
    resize: true,
  })
  loading.value = false
}

onMounted(() => {
  window.addEventListener('message', handleMessage)
  loadCalendly()
})

onUnmounted(() => {
  window.removeEventListener('message', handleMessage)
})

watch(() => props.coach, () => {
  loadCalendly()
})

</script>

<style scoped lang="scss">

</style>
