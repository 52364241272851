<script setup lang="ts">
import {nextTick, onBeforeUnmount, ref} from "vue"
import useAxios from "@/composables/useAxios"
import ALoader from "@/components/ALoader.vue"
import useRouteTo from "@/composables/useRouteTo"
import CoachHero from "@/components/CoachHero.vue"
import CoachServiceCard from "@/components/CoachServiceCard.vue"
import {useRouter} from "vue-router"
import {useHead} from "@unhead/vue"
import {loadStripe, type StripeEmbeddedCheckout} from "@stripe/stripe-js"

useHead({ title: 'Checkout' })

const {routeTo, ROUTES} = useRouteTo()
const router = useRouter()

const props = defineProps<{
  appointmentId: string | number;
}>()

const {axios} = useAxios()
const appointment = ref<App.Models.Appointment>()
const checkout = ref<StripeEmbeddedCheckout>()

const onComplete = async () => {
  await router.replace(routeTo(ROUTES.USERS.APPOINTMENT, {appointmentId: props.appointmentId}))
}
const load = async () => {
  const {data: {clientSecret, appointment: loadAppointment}} = await axios.post(`/appointments/${props.appointmentId}/checkout`)
  appointment.value = loadAppointment
  nextTick(async () => {
    const stripe = await loadStripe(import.meta.env.VITE_STRIPE_KEY)
    checkout.value = await stripe!.initEmbeddedCheckout({
      clientSecret: clientSecret,
      onComplete
    })
    checkout.value.mount('#checkout')
  }).then()
}

onBeforeUnmount(() => {
  if (checkout.value) {
    checkout.value.destroy()
  }
})

</script>

<template>
  <a-loader :action="load">
    <coach-hero
      :coach="appointment!.coach!"
      return-text="Change Date"
      :return-to="routeTo(ROUTES.USERS.APPOINTMENT_REQUEST, {coachId: appointment!.coach_id, serviceId: appointment!.service_id})"
      hide-bio
    />
    <v-container>
      <v-row>
        <v-col
          order="2"
          order-md="1"
          cols="12"
          md="6"
          class="d-flex flex-column gr-6"
        >
          <h1 class="heading">
            Booking
          </h1>
          <div id="checkout" />
        </v-col>
        <v-col
          order="1"
          order-md="2"
          cols="12"
          md="6"
          class="d-flex flex-column gr-6"
        >
          <h1 class="heading">
            Service
          </h1>
          <coach-service-card
            :service="appointment!.service!"
          />
        </v-col>
      </v-row>
    </v-container>
  </a-loader>
</template>

<style scoped lang="scss">
.heading {
  color: rgb(var(--v-theme-primary));
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 26px;
  font-weight: 600;
}

.label {
  color: #000;
  opacity: 1;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  white-space: break-spaces;
}

.sub-label {
  color: #000;
  opacity: 1;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Lato, sans-serif;
  font-size: 20px;
  letter-spacing: 0.6px;
  white-space: pre-line;
}
</style>
