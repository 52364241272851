<script lang="ts" setup>
import ReviewCard from "@/components/ReviewCard.vue"
import useAxios from "@/composables/useAxios"
import {onMounted, ref} from "vue"
import useFile from "@/composables/useFile"
import route from "@/plugins/route"
import router from "@/router"
import FaqItem from "@/components/FaqItem.vue"
import AppAddressField from "@/components/AppAddressField.vue"
import FocusAreaCard from "@/components/FocusAreaCard.vue"
import useRouteTo from "@/composables/useRouteTo"

const {axios} = useAxios()
const fileUrl = useFile()
const {routeTo, ROUTES} = useRouteTo()

const focusAreas = ref<App.Models.FocusArea[]>()
const rnSpotlight = ref<App.Models.User[]>()
const reviews = ref()

const loadFocusAreas = async () => {
  const {data} = await axios.get('/focus-areas', {
    params: {
      itemsPerPage: -1,
    }
  })

  focusAreas.value = data.data
}

const loadRnSpotlight = async () => {
  try {
    const {data} = await axios.get('/rn-spotlight')

    rnSpotlight.value = data
  } catch (e) {
    if (e?.status === 404) {
      return
    }

    throw e
  }
}

const loadReviews = async () => {
  const {data} = await axios.get('/reviews', {
    params: {
      limit: 12,
      min_stars: 3,
    }
  })

  reviews.value = data
}

onMounted(() => {
  Promise.all([
    loadFocusAreas(),
    loadRnSpotlight(),
    loadReviews(),
  ])
})

const searchForm = ref({
  location: '',
  latitude: null,
  longitude: null,
  format: '',
  delivery_method: '',
  focus_area: '',
})

const search = async () => {
  await router.push(route(ROUTES?.SEARCH, undefined, searchForm.value))
}

</script>

<template>
  <!-- Hero with Search -->
  <div class="section-hero-main">
    <v-container>
      <h1 class="text-center">
        Prime Health Coaching
      </h1>

      <div class="hero-split">
        <div class="hero-info">
          <h2 class="text-white">
            Expert Health Professionals Creating Personalized Solutions
          </h2>

          <h4>Health Management</h4>

          <p>
            Our Expert Health Coaches have a background in nursing and understand the complexities of your health
            conditions. They can tailor a program specifically to meet your individual needs.
          </p>

          <h4>Proven Results</h4>

          <p>
            Our Expert Health Coaches have successfully supported many individuals in achieving better health outcomes,
            which often contributes to a reduction in overall healthcare costs. While individual results may vary, many
            clients experience positive, lasting improvements that can lead to more sustainable health and cost savings.
          </p>

          <h4>HSA/FSA Eligibility</h4>

          <p>
            The cost of Health Coaching may be eligible for reimbursement through your HSA/FSA. Contact your HSA/FSA
            administrator for details.
          </p>
        </div>
        <div class="hero-form-container">
          <v-card
            class="hero-form"
            rounded="lg"
          >
            <v-card-title class="text-primary text-center text-wrap">
              Let’s find you the<br>
              perfect Health Coach
            </v-card-title>

            <v-card-text>
              <app-address-field
                v-model="searchForm.location"
                :types="[]"
                v-model:latitude="searchForm.latitude"
                v-model:longitude="searchForm.longitude"
                label="Location"
              />

              <v-radio-group
                label="Format"
                v-model="searchForm.format"
                inline
              >
                <v-radio
                  label="Group"
                  value="Group"
                />
                <v-radio
                  label="One-on-one"
                  value="One-on-one"
                />
                <v-radio
                  label="Either"
                  :value="null"
                />
              </v-radio-group>

              <v-radio-group
                label="Delivery Method"
                v-model="searchForm.delivery_method"
                inline
              >
                <v-radio
                  label="In-person"
                  value="In-person"
                />
                <v-radio
                  label="Virtual"
                  value="Virtual"
                />
                <v-radio
                  label="Either"
                  :value="null"
                />
              </v-radio-group>

              <v-select
                label="Focus Area"
                :items="focusAreas"
                item-title="name"
                item-value="id"
                v-model="searchForm.focus_area"
              />

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  variant="elevated"
                  size="large"
                  @click="search"
                >
                  Search
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-container>
  </div>

  <!-- Key Focus Areas -->
  <v-container>
    <h2
      class="text-primary"
      id="focus-areas"
    >
      Health Focus Areas
    </h2>

    <div class="py-8 focus-area-items">
      <focus-area-card
        :focus-area="focusArea"
        v-for="focusArea in focusAreas"
        :key="focusArea.id"
      />
    </div>
  </v-container>

  <!-- RN Spotlight -->
  <div
    class="section-rn-spotlight"
    v-if="rnSpotlight"
  >
    <v-carousel
      hide-delimiters
      cycle
      :interval="10000"
    >
      <v-carousel-item
        v-for="rn of rnSpotlight"
        :key="rn.id"
        class="w-100"
      >
        <v-container>
          <v-row class="py-8">
            <v-col
              cols="12"
              sm="6"
            >
              <v-img
                v-if="rn.file_id"
                :aspect-ratio="4/3"
                :src="fileUrl(rn.file_id, 'original')"
                cover
                position="top center"
                rounded="lg"
              />
            </v-col>
            <v-col
              class="text-right text-white"
              cols="12"
              sm="6"
            >
              <h2 class="text-uppercase spotlight-heading">
                Spotlight On
              </h2>
              <div>
                <v-btn
                  :to="routeTo(ROUTES.COACH_PROFILE, { coachId: rn.id })"
                  append-icon="mdi-open-in-new"
                  class="rn-spotlight-name"
                  size="large"
                  variant="text"
                >
                  <span class="text-pre-wrap text-right">{{ rn.full_name }} {{ rn?.title }}</span>
                </v-btn>
              </div>
              <div>
                <p class="pt-4 text-left">
                  <span class="text-pre-wrap">{{ rn.bio }}</span>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </div>

  <!-- FAQ -->
  <div>
    <v-container>
      <h2
        class="faq-heading"
        id="faq"
      >
        FAQ
      </h2>

      <div class="faq-container">
        <faq-item
          :style="{
            '--faq-offset-current': 1,
            '--faq-offset-total': 5,
          }"
        >
          <template #num>
            1
          </template>

          <template #question>
            What is an <em>Expert Health Coach</em>?
          </template>

          <template #default>
            <p>
              Our Health Coaches are healthcare professionals with a nursing background who combine their clinical
              expertise with specialized coaching skills to help individuals achieve health and wellness goals. They
              work closely with clients to provide education, empowerment, support, accountability, and personalized
              guidance. By focusing on holistic health, health coaches address the physical, mental, social, emotional,
              and spiritual aspects of wellness. They are committed to helping you achieve meaningful and long-lasting
              results.
            </p>
          </template>
        </faq-item>
        <faq-item
          :style="{
            '--faq-offset-current': 2,
            '--faq-offset-total': 5,
          }"
        >
          <template #num>
            2
          </template>

          <template #question>
            Why Choose a <em>Prime Health Coach</em>?
          </template>

          <template #default>
            <p>
              Choosing a Prime Health Coach offers a blend of scientific expertise and compassionate care. Our Expert
              Health Coaches bring deep knowledge of health conditions and treatments, combined with a holistic approach
              that considers all aspects of your well-being—physical, emotional, spiritual, social, and psychological.
            </p>
            <p>
              Beyond their nursing education, Prime Health Coaches undergo specialized training to provide personalized
              guidance tailored to your specific needs. They offer strong support and motivation, and help you navigate
              the healthcare system effectively. With a focus on preventive care and wellness, Health Coaches are
              well-equipped to guide you toward achieving your health goals in a comprehensive and informed manner.
            </p>
          </template>
        </faq-item>
        <faq-item
          :style="{
            '--faq-offset-current': 3,
            '--faq-offset-total': 5,
          }"
        >
          <template #num>
            3
          </template>

          <template #question>
            How do I pay using my <em>FSA/HSA funds</em>?
          </template>

          <template #default>
            <p>
              To pay for Health Coaching using FSA/HSA funds, follow these steps:
            </p>
            <ol>
              <li>
                Get a Medical Necessity Letter: First, request a letter of medical necessity from your healthcare
                provider. Submit this letter to your FSA/HSA administrator.
              </li>
              <li>Obtain Approval: Then, get approval from your FSA/HSA administrator.</li>
              <li>
                Schedule and Pay: Once approved, you can schedule a session with one of our Expert Health Coaches and
                pay using your FSA/HSA card directly at
                <a
                  class="text-primary"
                  href="https://www.primehealthrn.com"
                >www.primehealthrn.com</a>.
              </li>
            </ol>
            <p>
              Prime Health Coaching is here to assist you with an easy-to-complete letter of medical necessity form. For
              more details, please contact us at <a
                class="text-primary"
                href="mailto:primehealthrnuser@gmail.com"
              >primehealthrnuser@gmail.com</a>.
            </p>
          </template>
        </faq-item>
        <faq-item
          :style="{
            '--faq-offset-current': 4,
            '--faq-offset-total': 5,
          }"
        >
          <template #num>
            4
          </template>

          <template #question>
            How do I book an appointment with a <em>Prime Health Coach</em>?
          </template>

          <template #default>
            <p>
              Once you have found your preferred Health Coach, select the “Book Now” button for the service you have
              selected. If you don’t already have a user account, you will be prompted to create one. This ensures
              seamless flow and communication with your Health Coach. You will then be directed to the scheduling page
              for your Health Coach and can choose a preferred day and time to meet with your Coach. After you have
              scheduled your appointment, you will then pay for your session to complete the booking. Once the session
              is complete, we will send you a link to be able to write a review for your Prime Health Coach.
              Rescheduling requests are handled directly by each individual Health Coach.
            </p>
          </template>
        </faq-item>
        <faq-item
          :style="{
            '--faq-offset-current': 5,
            '--faq-offset-total': 5,
          }"
        >
          <template #num>
            5
          </template>

          <template #question>
            I’m a provider. How do I learn more about your services?
          </template>

          <template #default>
            <p>
              Wouldn’t you love to offer Expert Health Coaching to support your patients but are constrained by budget
              or time? Prime Health Coaching is here to help! We’d love to discuss how we can partner with your practice
              to provide a valuable resource for your patients and their caregivers—at no cost to you. Contact us at
              <a
                class="text-primary"
                href="mailto:primehealthrn@gmail.com"
              >primehealthrn@gmail.com</a>
              or use the information at the bottom of our website to schedule a conversation about how we can
              collaborate to improve patient outcomes.
            </p>
          </template>
        </faq-item>
      </div>
    </v-container>
  </div>

  <!-- Reviews -->
  <div class="section-reviews">
    <v-container>
      <h2 class="section-review-heading">
        Reviews
      </h2>

      <div>
        <v-slide-group
          show-arrows
        >
          <v-slide-group-item
            v-for="review in reviews"
            :key="review.i"
          >
            <review-card
              style="width: 245px;"
              :review="review"
            />
          </v-slide-group-item>
        </v-slide-group>
      </div>
    </v-container>
  </div>
</template>

<style scoped lang="scss">

.section-hero-main {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%),
  url(/resources/img/helping_hands.jpeg) lightgray 50% / cover no-repeat;
  color: white;
  padding: 60px 120px;

  h1 {
    font-size: 72px;
    font-weight: 800;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 44px;
  }

  h1,
  h2,
  h3,
  h4 {
    color: rgb(var(--v-theme-primary))
  }

  @media (max-width: 800px) {
    padding: 0;

    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 36px;
    }
  }
}

.hero-split {
  display: flex;
  gap: 78px;

  @media(max-width: 800px) {
    flex-direction: column;
  }

  .hero-info {
    flex: 5;
  }

  .hero-form-container {
    align-self: center;
    width: 100%;
  }
}

.hero-form-container {
  max-width: 448px;
}

.hero-form {
  padding: 8px 12px;
}

.hero-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section-rn-spotlight {
  background: #626262;

  .spotlight-heading {
    font-size: 20px;
    font-weight: 400;
  }
}

.section-reviews {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%),
  url(/resources/img/nature_rocks.jpg) lightgray 50% / cover no-repeat;
}

.focus-area-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.rn-spotlight-name {
  text-transform: none;
  font-size: 24px;
  font-weight: 800;
  margin-right: -16px;
}

.faq-heading {
  font-size: 40px;
  color: #4B4B4B;
  padding: 12px 0;
}

.section-reviews {
  padding: 60px 0;

  .section-review-heading {
    font-size: 44px;
    color: #8DC242;
    margin-bottom: 24px;
  }
}
</style>
