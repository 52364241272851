import posthog from 'posthog-js'

export function usePostHog() {
  posthog.init('phc_G93n1hGIdcbw0JdhP0AU1isscncy3hErURU4OgH4wOA', {
    api_host: 'https://us.i.posthog.com',
    capture_pageview: false,
    capture_pageleave: false,
  })

  return {
    posthog
  }
}
