<script setup lang="ts">
import useAxios from "@/composables/useAxios"
import {computed, onMounted, ref, watch} from "vue"
import useRouteTo from "@/composables/useRouteTo"
import route from "@/plugins/route"
import AMainSection from "@/components/AMainSection.vue"
import {useRoute, useRouter} from "vue-router"
import CoachRating from "@/components/CoachRating.vue"
import useFile from "@/composables/useFile"
import AppAddressField from "@/components/AppAddressField.vue"
import MapPin from '@/images/map_pin.svg'

const {ROUTES} = useRouteTo()
const {axios} = useAxios()
const currentRoute = useRoute()
const {$auth} = useAuth()
const fileUrl = useFile()

useHead({ title: 'Service Search' })

const focusAreas = ref<App.Models.FocusArea[]>()

import {useDebounceFn, useGeolocation} from '@vueuse/core'
import useAuth from "@/composables/useAuth"
import MapCard from "@/components/MapCard.vue"
import {useHead} from "@unhead/vue"

const { coords, pause } = useGeolocation()
const router = useRouter()

watch(coords, () => {
  if (Number.isFinite(coords.value.latitude) && Number.isFinite(coords.value.longitude)) {
    pause()
    if (!search.value.map_center_latitude && !search.value.map_center_longitude) {
      center.value = {lat: coords.value.latitude, lng: coords.value.longitude}
    }
  }
})

const infoWindowOpen = ref<number | null>(null)
const openInfoWindow = (id) => {
  if (infoWindowOpen.value === id) {
    infoWindowOpen.value = null
  } else {
    infoWindowOpen.value = id
  }
}
const isInfoWindowOpen = (id) => {
  return infoWindowOpen.value === id
}

const loadFocusAreas = async () => {
  const { data: { data }} = await axios.get('/focus-areas', {
    params: {
      itemsPerPage: -1
    }
  })

  focusAreas.value = data
}

onMounted(() => {
  loadFocusAreas()
  loadCoaches()
})


const initialFocusAreas = currentRoute.query.focus_area
  ? [Number(currentRoute.query.focus_area)]
  : (Array.isArray(currentRoute.query.focus_areas) ? currentRoute.query.focus_areas.map(Number) : (!isNaN(currentRoute.query.focus_areas) ? [Number(currentRoute.query.focus_areas)] : []))

const results = ref<App.Models.User[]>([])
const search = ref({
  q: currentRoute.query.q || '',
  location: currentRoute.query.location || '',
  map_center_latitude: Number(currentRoute.query.map_center_latitude) || undefined,
  map_center_longitude:  Number(currentRoute.query.map_center_longitude) || undefined,
  map_zoom:  Number(currentRoute.query.map_zoom) || 7,
  format: currentRoute.query.format,
  offers_free_services: currentRoute.query.offers_free_services === "1" ? 1 : 0,
  delivery_method: currentRoute.query.delivery_method,
  min_rating: currentRoute.query.min_rating ? Number(currentRoute.query.min_rating) : undefined,
  focus_areas: initialFocusAreas,
})

const center = ref({lat: search.value.map_center_latitude || 33.412352742406064, lng: search.value.map_center_longitude || -84.3615070539124})
const centerProxy = computed({
  get(): {lat: number, lng: number} {
    return center.value
  },
  set(value: {lat: number, lng: number}) {
    center.value = value
    search.value.map_center_latitude = value.lat
    search.value.map_center_longitude = value.lng
  }
})

const handleBoundsChange = (map) => {
  const newCenter = map.getCenter()
  search.value.map_center_latitude = newCenter.lat()
  search.value.map_center_longitude = newCenter.lng()
}

const handleZoomChange = (zoom) => {
  search.value.map_zoom = zoom
}

watch(search, () => {
  debounceSearch()
}, {deep: true})

const pagination = ref({
  nextPage: null,
  total: 0,
  page: 1
})
const loadCoaches = async (isIntersecting = true) => {
  if (!isIntersecting) {
    return
  }
  router.replace({query: search.value}).then()
  const { data } = await axios.get('/search', {
    params: {
      ...search.value,
      page: pagination.value.page
    }
  })

  results.value = results.value.concat(data.data)
  pagination.value.nextPage = data.next_page_url
  pagination.value.page = data.current_page + 1
  pagination.value.total = data.total
}
const debounceSearch = useDebounceFn(async () => {
  pagination.value.page = 1
  pagination.value.nextPage = null
  pagination.value.total = 0
  results.value = []
  await loadCoaches()
}, 500)

if ($auth.user?.latitude && $auth.user?.longitude) {
  if (!search.value.map_center_latitude && !search.value.map_center_longitude) {
    center.value = {lat: $auth.user.latitude, lng: $auth.user.longitude}
  }
}
</script>

<template>
  <a-main-section
    heading="Service Search"
    return-text="Prime Health RN"
    :return-to="route(ROUTES.HOME)"
  >
    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <h2 class="font-weight-bold mb-4">
          Find the right coach for you
        </h2>
        <v-text-field
          v-model="search.q"
          label="Search"
        />

        <app-address-field
          v-model="search.location"
          v-model:latitude="centerProxy.lat"
          v-model:longitude="centerProxy.lng"
          :types="[]"
          label="Location"
        />

        <h4 class="font-weight-bold mt-2">
          Other
        </h4>
        <v-checkbox
          v-model="search.offers_free_services"
          :true-value="1"
          :false-value="0"
          label="Offers Free Services"
          hide-details="auto"
          density="compact"
        />

        <h4 class="font-weight-bold mt-4">
          Format
        </h4>
        <v-radio-group
          v-model="search.format"
          inline
        >
          <v-radio
            label="Group"
            value="Group"
          />
          <v-radio
            label="One-on-one"
            value="One-on-one"
          />
          <v-radio
            label="Either"
            :value="null"
          />
        </v-radio-group>

        <h4 class="font-weight-bold mt-2">
          Delivery Method
        </h4>
        <v-radio-group
          v-model="search.delivery_method"
          inline
        >
          <v-radio
            label="In-person"
            value="In-person"
          />
          <v-radio
            label="Virtual"
            value="Virtual"
          />
          <v-radio
            label="Either"
            :value="null"
          />
        </v-radio-group>

        <h4 class="font-weight-bold">
          Minimum Rating
        </h4>
        <coach-rating v-model="search.min_rating" />

        <h4 class="font-weight-bold mt-4">
          Focus Areas
        </h4>
        <div class="focus-areas">
          <div
            v-for="focusArea in focusAreas"
            :key="focusArea.id"
            class="focus-area-item"
          >
            <v-checkbox-btn
              v-model="search.focus_areas"
              :value="focusArea.id"
              class="focus-area-item-btn"
            />
            <div class="focus-area-item-text">
              {{ focusArea.name }}
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        md="6"
        cols="12"
      >
        <g-map-map
          class="map overflow-hidden"
          :center="centerProxy"
          :zoom="8"
          @click="infoWindowOpen = null"
          @bounds_changed="handleBoundsChange"
          @zoom_changed="handleZoomChange"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }"
          map-type-id="terrain"
        >
          <g-map-marker
            v-for="coach in results"
            :key="coach.id"
            :icon="MapPin"

            @click="openInfoWindow(coach.id)"
            :position="{ lat: coach.latitude, lng: coach.longitude }"
          >
            <GMapInfoWindow
              :opened="isInfoWindowOpen(coach.id)"
              :options="{ headerDisabled: true }"
              @closeclick="infoWindowOpen = null"
            >
              <map-card :coach="coach" />
            </GMapInfoWindow>
          </g-map-marker>
        </g-map-map>
      </v-col>
    </v-row>
    <div v-if="results">
      <h2 class="my-8 text-primary">
        {{ pagination.total }} Result{{ pagination.total === 1 ? '' : 's' }}
      </h2>

      <v-row>
        <v-col
          v-for="result in results"
          :key="result.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card height="100%">
            <v-img
              v-if="result.file_id"
              :aspect-ratio="4/3"
              cover
              position="top center"
              :src="fileUrl(result.file_id, 'original')"
            />

            <v-card-title>{{ result.full_name }}</v-card-title>

            <v-card-text>
              {{ result.title }}
            </v-card-text>

            <div class="d-flex px-3 pb-3">
              <div>
                <v-icon
                  color="primary"
                  icon="mdi-map-marker-outline"
                />
              </div>
              <div>
                {{ result.display_address }}
              </div>
            </div>

            <v-card-actions>
              <v-spacer />
              <v-btn
                :to="route(ROUTES.COACH_PROFILE, { coachId: result.id })"
                color="primary"
                size="x-large"
                variant="elevated"
                text="View Profile"
              />
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          class="d-flex align-center justify-center"
          v-intersect="loadCoaches"
          v-if="pagination.nextPage"
        >
          <v-progress-circular
            indeterminate
          />
        </v-col>
      </v-row>
    </div>
  </a-main-section>
</template>

<style lang="scss" scoped>

.focus-areas {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media(max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.focus-area-item {
  display: flex;
  align-items: center;
}

.focus-area-item-btn {
  flex: none;
}

.focus-area-item-text {
  flex-grow: 1;
}

:deep(.vue-map-container) {
  height: 100%;
}

.map {
  border-radius: 12px;
  min-height: 400px;
}
</style>
